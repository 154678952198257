import { Index } from "flexsearch";

import busStopJSON from "./bus_stops.json";

export type BusStopInfo = {
  BusStopCode: string;
  RoadName: string;
  Description: string;
  Latitude: number;
  Longitude: number;
};

const index = new Index({
  tokenize: "forward",
  resolution: 5,
});

export const busStopArr = busStopJSON;

export const keyedBusStopInfo = busStopArr.reduce((acc, stopInfo) => {
  acc[stopInfo.BusStopCode] = stopInfo;
  return acc;
}, {} as { [BusStopCode: string]: BusStopInfo });

busStopArr.forEach((stop, idx) => {
  index.add(idx, `${stop.BusStopCode} ${stop.RoadName} ${stop.Description}`);
});

export default index;
